@font-face {
  font-family: "Inter";
  font-weight: 300;
  src: url("/fonts/inter-light.ttf") format("trueType");
}
@font-face {
  font-family: "Inter";
  font-weight: 400;
  src: url("/fonts/inter-regular.ttf") format("trueType");
}
@font-face {
  font-family: "Inter";
  font-weight: 500;
  src: url("/fonts/inter-medium.ttf") format("trueType");
}
@font-face {
  font-family: "Inter";
  font-weight: 600;
  src: url("/fonts/inter-semibold.ttf") format("trueType");
}
@font-face {
  font-family: "Inter";
  font-weight: 700;
  src: url("/fonts/inter-bold.ttf") format("trueType");
}

@font-face {
  font-family: "Open Sauce Two";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("/fonts/OpenSauceTwo-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Open Sauce Two";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url("/fonts/OpenSauceTwo-LightItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Open Sauce Two";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/fonts/OpenSauceTwo-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Open Sauce Two";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("/fonts/OpenSauceTwo-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "Open Sauce Two";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src:
    url("/fonts/OpenSauceTwo-Medium.ttf") format("truetype"),
    url("/fonts/OpenSauceTwo-Medium.woff") format("woff");
}
@font-face {
  font-family: "Open Sauce Two";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url("/fonts/OpenSauceTwo-MediumItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Open Sauce Two";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("/fonts/OpenSauceTwo-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Open Sauce Two";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url("/fonts/OpenSauceTwo-SemiBoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Open Sauce Two";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("/fonts/OpenSauceTwo-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Open Sauce Two";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("/fonts/OpenSauceTwo-BoldItalic.ttf") format("truetype");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

#__next {
  height: 100%;
}

body {
  overflow: auto;
}

.full-height-and-width-for-react-full-screen {
  width: 100%;
  height: 100%;
}

.react-pdf-page {
  border: 1px solid rgba(0, 0, 0, 0.05);
  height: 100%;

  .annotationLayer .popup {
    min-width: 200px;
  }
}

.react-pdf-document {
  width: 100%;
  height: 100%;
}
